import React from 'react'
import Main from '../../views/Main'

const Body = () => {
  return (
    <>
      <Main />
    </>
  )
}

export default Body